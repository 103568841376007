<script>
export default {
  metaInfo: {
    title: "Email Server Configuration",
  }
};
</script>

<script setup>
import PhpServiceFormView from '@/components/basic/php-wrappers/PhpServiceFormView.vue';
</script>

<template>
  <PhpServiceFormView url="?f=administration&f2=emailServerConfiguration"/>
</template>

